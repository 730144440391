import { Field, Formik } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomTextInput from "../../../SharedComponents/CustomTextInput";
import { types } from "../../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import CryptoLogo from "../../../../Assets/Logo/logo.png";
import { useEffect } from "react";
import { useRef } from "react";

const ForgetPassword = () => {
  const emailRef = useRef(null);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { emailOtpForCreateNewPassordloading } = useSelector((state) => ({
    emailOtpForCreateNewPassordloading:
      state.AuthReducer.emailOtpForCreateNewPassordloading,
  }));

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center h100vh">
        <div className="text-center">
          <div className=" ">
            <img src={CryptoLogo} />
            {/* <p className="login-heading ">Thank you for the information you provided about the connection</p> */}
          </div>
          <div className="">
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={(values) => {
                let data = {
                  createNewpassEmailSend: {
                    email: values.email,
                  },

                  history: history,
                };
                dispatch({
                  type: types.EmailForOtpCreateNewPasswordRequest,
                  payload: data,
                });
              }}
              // validationSchema={Constants.OtpSchema}
            >
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <>
                  <div className="row  d-flex justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 mb-3 ">
                      <Field name="email">
                        {({ field, meta }) => (
                          <CustomTextInput
                            label={"Email"}
                            type="text"
                            error={meta.touched ? meta.error : null}
                            onChange={handleChange("email")}
                            placeHolderText={"Enter Your Email"}
                            value={values.email}
                            onBlur={handleBlur("email")}
                            changeWidth={true}
                            innerRef={(el) => {
                              field.ref = el;
                              emailRef.current = el;
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                console.log("enter pressed");
                                e.preventDefault(); // Prevent form submission
                                // Trigger form submission
                                handleSubmit();
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="parimarybtn"
                      onClick={handleSubmit}
                    >
                      {emailOtpForCreateNewPassordloading ? (
                        <div className="whitespinner">
                          <div className="bounce1"></div>
                          <div className="bounce2"></div>
                          <div className="bounce3"></div>
                        </div>
                      ) : (
                        <>Submi ssst </>
                      )}
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
          <div className="mt8">
            <NavLink className="text-decoration-none  " to="/">
              <span className=" fs16 ligh-primary">Back to the login page</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
